import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import localeEsMX from '@angular/common/locales/es-MX';
import localeEsMXExtra from '@angular/common/locales/extra/es-MX';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEsMX, 'es-MX', localeEsMXExtra);
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ArrendatariosComponent } from './components/arrendatarios/arrendatarios.component';
import { ServiciosService } from './services/servicios.service';
import { LocalesComponent } from './components/locales/locales.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AlertComponent } from './components/alert/alert.component';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './components/home/home.component';
import { AppInitService } from './services/app.init';
import { ContratosComponent } from './components/contratos/contratos.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { PendientesComponent } from './components/pendientes/pendientes.component';

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}



@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    ArrendatariosComponent,
    LocalesComponent,
    AlertComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    ContratosComponent,
    NotfoundComponent,
    PendientesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppInitService], multi: true },
    ServiciosService,
    AppInitService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
