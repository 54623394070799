import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../../services/servicios.service';
import { Contrato } from 'src/app/model/Contrato';
import { AlertService } from '../../services/alert.service';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.css']
})
export class ContratosComponent implements OnInit {

  contratos: Contrato[] = new Array<Contrato>();
  cargando = false;

  constructor(private serviciosService: ServiciosService, private alertService: AlertService) { }

  ngOnInit() {


    this.cargando = true;

    this.serviciosService.CurrentPageTitle = 'Contratos';
    this.alertService.clear();

    this.serviciosService.getContratos('NA').subscribe (
      res => {
        this.cargando = false;
        this.contratos = res as Contrato[];
      },
      err => {
        this.cargando = false;
        if (err) {
          this.alertService.error(err);
          // alert(err);
        }
      }
    );
  }

}
