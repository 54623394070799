import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../../services/servicios.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  constructor(private serviciosService: ServiciosService) { }

  ngOnInit() {
  }

  isLoggedIn() {
    return this.serviciosService.isLoggedIn();
  }

}
