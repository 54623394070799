import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../../services/servicios.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private serviciosService: ServiciosService, private alertService: AlertService) { }

  ngOnInit() {
    this.serviciosService.CurrentPageTitle = 'Leasing';
    this.alertService.clear();
  }

}
