import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../../services/servicios.service';
import { Arrendatario } from 'src/app/model/Arrendatario';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-arrendatarios',
  templateUrl: './arrendatarios.component.html',
  styleUrls: ['./arrendatarios.component.css']
})
export class ArrendatariosComponent implements OnInit {

  arrendatarios: Arrendatario[] = new Array<Arrendatario>();
  cargando = false;
  showModalBox = false;
  datosArrendatario: Arrendatario = null;

  constructor(private serviciosService: ServiciosService, private alertService: AlertService) { }

  ngOnInit() {

    this.cargando = true;

    this.serviciosService.CurrentPageTitle = 'Arrendatarios';
    this.alertService.clear();

    this.serviciosService.getArrendatarios().subscribe (
      res => {
        this.cargando = false;
        this.arrendatarios = res as Arrendatario[];
      },
      err => {
        this.cargando = false;
        if (err) {
          this.alertService.error(err);
          // alert(err);
        }
      }
    );
  }

  private VerDatosDelEmail(emailAddr: any): void {

  }

  private VerDatos(arrID: any): void {
    this.cargando = true;
    this.alertService.clear();
    this.serviciosService.getArrendatario(arrID).subscribe (
      res => {
        this.cargando = false;
        this.datosArrendatario = res as Arrendatario;
      },
      err => {
        this.cargando = false;
        if (err) {
          this.alertService.error(err);
          // alert(err);
        }
      }
    );
    this.showModalBox = true;
  }

  private CerrarModal(grabar: boolean): void {
    this.showModalBox = false;
    if (grabar) {
      // Grabar aqui los datos
      alert(this.datosArrendatario.Nombre);
    }
  }
}
