import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


import { ServiciosService  } from '../services/servicios.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private serviciosService: ServiciosService
        ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.serviciosService.logout(true);
            }

            const error = err.error.ExceptionMessage || err.error.message || err.statusText;

            if (error === 'TOKEN_EXPIRED') {
                this.serviciosService.logout(true);
            }

            return throwError(error);
        }));
    }
}
