import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Arrendatario } from '../model/Arrendatario';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RespuestaLogin } from '../model/RespuestaLogin';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  private currentUserSubject: BehaviorSubject<RespuestaLogin>;
  public currentUser: Observable<RespuestaLogin>;
  public CurrentPageTitle = 'Leasing';

  API_URI = environment.api_uri;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<RespuestaLogin>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private respuestaLogin: BehaviorSubject<RespuestaLogin>;

  isLoggedIn() {
    const tokenid = this.getToken();

    if (tokenid) {
      return true;
    } else {
      return false;
    }
  }

  getNombreUsaurio() {
    if (this.currentUserSubject && this.currentUserSubject.value && this.currentUserSubject.value.NombreUsuario) {
      return this.currentUserSubject.value.NombreUsuario;
    } else {
      return '???????';
    }
  }

  getNombrArrendatario() {
    if (this.currentUserSubject && this.currentUserSubject.value && this.currentUserSubject.value.NombreEmpresa) {
      return this.currentUserSubject.value.NombreEmpresa;
    } else {
      return '???????';
    }
  }

  getToken() {
    if (this.currentUserSubject && this.currentUserSubject.value && this.currentUserSubject.value.Token) {
      return this.currentUserSubject.value.Token;
    } else {
      return '';
    }
  }

  clearCurrentUser() {
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject = new BehaviorSubject<RespuestaLogin>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  logout(redirect: boolean) {
    this.http.get(`${this.API_URI}/logout`).subscribe(
      resp => {
        this.clearCurrentUser();
        // console.log(resp);
        if (redirect === true) {
          this.router.navigate(['login']);
        }
      },
      error => {
        this.clearCurrentUser();
        console.error(error);
        if (redirect === true) {
          this.router.navigate(['login']);
        }
      }
    );
  }

  login(user: string, passwd: string): Observable<any> {
    const auth = 'Basic ' + btoa(user + ':' + passwd);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth
      })
    };
    return this.http.get(`${this.API_URI}/login`, httpOptions)
      .pipe(map(resp => {

        this.setSession(resp);

        return resp;
      }));
  }

  private setSession(authResult) {
    if (authResult.Token) {
      if (authResult.EsError === false) {
        sessionStorage.setItem('currentUser', JSON.stringify(authResult));
        this.currentUserSubject.next(authResult);
      } else {
        this.clearCurrentUser();
      }
    } else {
      this.clearCurrentUser();
    }
    // sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  }

  // #region Contratos
  getContratos(ifIltro: string): Observable<Arrendatario> {
    return this.http.get(`${this.API_URI}/contratos/${ifIltro}`);
  }
  // #endregion

  // #region Arrendatarios
  getArrendatarios(): Observable<Arrendatario> {
    return this.http.get(`${this.API_URI}/arrendatarios`);
  }

  getArrendatario(id: string): Observable<Arrendatario> {
    return this.http.get(`${this.API_URI}/arrendatarios/${id}`);
  }

  deleteArrendatario(id: string) {
    return this.http.delete(`${this.API_URI}/arrendatarios/${id}`);
  }

  saveArrendatario(arrendatario: Arrendatario): Observable<Arrendatario> {
    return this.http.post(`${this.API_URI}/arrendatarios`, arrendatario);
  }

  updateArrendatario(id: string, arrendatario: Arrendatario): Observable<Arrendatario> {
    return this.http.put(`${this.API_URI}/arrendatarios/${id}`, arrendatario);
  }
  // #endregion

  // #region Locales

  getLocales(): Observable<Arrendatario> {
    return this.http.get(`${this.API_URI}/locales`);
  }

  // #endregion

  // #region Pendientes

  getPendientes(SoloArrs: string): Observable<Arrendatario> {
    return this.http.get(`${this.API_URI}/pendientes/${SoloArrs}`);
  }

  // #endregion
}
