import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../../services/servicios.service';
import { AlertService } from '../../services/alert.service';
import { Local } from '../../model/Local';

@Component({
  selector: 'app-locales',
  templateUrl: './locales.component.html',
  styleUrls: ['./locales.component.css']
})
export class LocalesComponent implements OnInit {

  locales: Local[] = new Array<Local>();
  cargando = false;

  constructor( private serviciosService: ServiciosService, private alertService: AlertService) { }

  ngOnInit() {
    this.cargando = true;

    this.serviciosService.CurrentPageTitle = 'Locales';
    this.alertService.clear();

    this.serviciosService.getLocales().subscribe (
      res => {
        this.cargando = false;
        // console.log(this.locales);
        this.locales = res as Local[];
      },
      err => {
        this.cargando = false;
        this.alertService.error(err);
        // alert(err);
      }
    );
  }

}
