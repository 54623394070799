import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { ServiciosService } from '../../services/servicios.service';
import { AlertService } from '../../services/alert.service';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private serviciosService: ServiciosService,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    if (this.serviciosService.isLoggedIn()) {
        this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.serviciosService.login(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          this.loading = false;
          if (data.EsError === false) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.alertService.error(data.ErrorMensaje);
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }
}
