import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ArrendatariosComponent } from './components/arrendatarios/arrendatarios.component';
import { LocalesComponent } from './components/locales/locales.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthGuard } from './helpers/auth.guard';
import { ContratosComponent } from './components/contratos/contratos.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { PendientesComponent } from './components/pendientes/pendientes.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard]  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'arrendatarios', component: ArrendatariosComponent, canActivate: [AuthGuard] },
  { path: 'contratos', component: ContratosComponent, canActivate: [AuthGuard] },
  { path: 'pendientes', component: PendientesComponent, canActivate: [AuthGuard] },
  { path: 'locales', component: LocalesComponent, canActivate: [AuthGuard]  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '**', component: NotfoundComponent }
  // { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
