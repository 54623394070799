import { Component, OnInit } from '@angular/core';
import { ServiciosService } from 'src/app/services/servicios.service';
import { AlertService } from 'src/app/services/alert.service';
import { Pendiente } from 'src/app/model/Pendiente';

@Component({
  selector: 'app-pendientes',
  templateUrl: './pendientes.component.html',
  styleUrls: ['./pendientes.component.css']
})
export class PendientesComponent implements OnInit {

  TotalPendiente: number;
  TotalIntereses: number;
  TotalIva: number;
  TotalRentas: number;
  pendientes: Pendiente[] = new Array<Pendiente>();
  cargando = false;

  constructor( private serviciosService: ServiciosService, private alertService: AlertService) { }

  ngOnInit() {
    this.cargando = true;

    this.serviciosService.CurrentPageTitle = 'Pendientes';
    this.alertService.clear();

    this.TotalPendiente = 0;
    this.TotalIntereses = 0;
    this.TotalIva = 0;
    this.TotalRentas = 0;

    this.serviciosService.getPendientes('-').subscribe (
      res => {
        this.cargando = false;
        // console.log(this.pendientes);
        this.pendientes = res as Pendiente[];
        this.pendientes.forEach(pendiente => {
          this.TotalPendiente += pendiente.PendienteTotal;
          this.TotalIntereses += pendiente.PendienteIntereses;
          this.TotalIva += pendiente.PendienteIva;
          this.TotalRentas += pendiente.PendienteRenta;
        });
      },
      err => {
        this.cargando = false;
        this.alertService.error(err);
        // alert(err);
      }
    );
  }

}
