import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
declare var window: any;

@Injectable()
export class AppInitService {

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  public init() {
    return from(
        // tslint:disable-next-line: only-arrow-functions
        fetch('assets/app-config.txt').then(function(response) {
        // fetch('assets/app-config.json').then(r => r.json()).then(j => { console.log(j);
          return response.json();
        })
      ).pipe(
        map((config) => {
        window.config = config;
        // console.log('window.config.api_uri:' + window.config.api_uri);
        return;
      })).toPromise();
  }
}
