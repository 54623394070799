import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ServiciosService } from '../services/servicios.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private serviciosService: ServiciosService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokenid = this.serviciosService.getToken();

        // console.log('Token:' + tokenid);

        if (tokenid && !request.headers.get('Authorization')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${tokenid}`
                }
            });
        }

        return next.handle(request);
    }
}